
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
export default defineComponent({
  props: {
    newData: Object,
    loading: Boolean,
  },
  setup(props) {
    return {
      dateFormat,
    };
  },
});
